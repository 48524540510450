import { updateChatStatus } from "../api/chats";

export const chatColumns = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 2,
    name: 'Title',
    key: 'title',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 3,
    name: 'Type',
    key: 'type',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 4,
    name: 'Bot Active',
    key: 'is_bot_active',
    type: 'boolean', // Булевое значение, будем использовать ToggleSwitch
    toggleFunction: (chatId: number, newStatus: boolean) => {
      updateChatStatus(chatId, newStatus);
    },
  },
  {
    id: 5,
    name: 'Recent Messages',
    key: 'recent_messages',
    type: 'array', // Массив строк, возможно модальное окно
  }
];
