import React from 'react';
import ToggleSwitch from './ToggleSwitch';
import { Column } from '../types/Column';

interface TableViewProps {
  columns: Column[];
  data: any[];
}

const TableView: React.FC<TableViewProps> = ({ columns, data }) => {

  const renderCell = (column: Column, row: any) => {
    const value = row[column.key];
    const empty = 'Empty';
    console.log(value);

    switch (typeof value) {
      case 'string':
        return <td>{row[column.key] || empty}</td>;

      case 'boolean':
        return (
          <td>
            <ToggleSwitch
              isActive={value}
              onToggle={(newState) => column.toggleFunction && column.toggleFunction(row.id, newState)}
            />
          </td>
        );

      case 'object':
        if (Array.isArray(value)) {
          return (
            <td>
              {value[value.length - 1] || empty}
            </td>
          );
        }

        return <td>{empty}</td>;

      default:
        return <td>{empty}</td>;
    }
  };

  return (
    <table className="data-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.id}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            {columns.map((column) => (
              <React.Fragment key={column.id}>
                {renderCell(column, row)}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableView;
