export const privateChatColumns = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 2,
    name: 'First Name',
    key: 'first_name',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 3,
    name: 'Last Name',
    key: 'last_name',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 4,
    name: 'Username',
    key: 'username',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 5,
    name: 'Type',
    key: 'type',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 6,
    name: 'Bot Active',
    key: 'is_bot_active',
    type: 'boolean', // Булевое значение, будем использовать ToggleSwitch
    toggleFunction: (chatId: number, newState: boolean) => {
      // Функция для переключения статуса бота
      console.log(`Тогглер для чата ${chatId} переключен на: ${newState}`);
    },
  },
  {
    id: 7,
    name: 'Recent Messages',
    key: 'recent_messages',
    type: 'array', // Массив строк, возможно модальное окно
  },
  {
    id: 8,
    name: 'Selected Tags',
    key: 'selected_tags',
    type: 'array', // Массив тегов, нужен дополнительный рендер
  },
];
