import React, { FC } from 'react';
import classNames from 'classnames';

interface ButtonProps {
  type?: 'button' | 'submit';
  text: string;
  onClick?: () => void;
  className?: string;
}

const Button: FC<ButtonProps> = ({ type = 'button', text, onClick, className }) => {
  return (
    <button type={type} className={classNames('button', className)} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
