export const quipColumns = [
  {
    id: 1,
    name: 'ID',
    key: 'id',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 2,
    name: 'Min Duplicate Count',
    key: 'minDuplicateCount',
    type: 'string', // Обычное текстовое значение
  },
  {
    id: 3,
    name: 'Quips',
    key: 'quips',
    type: 'array', // Массив, нужен дополнительный рендер
  },
  {
    id: 4,
    name: 'Keywords',
    key: 'keywords',
    type: 'array', // Массив, нужен дополнительный рендер
  },
];
